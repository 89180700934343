div:focus {
  outline: none;
}

.person-icon-value.safari-only {
  transform: translate(17px, 24px);
}

.person-icon-value {
  transform: translate(17px, 9px);
}

.person-icon-value-ie {
  display: none;
}

@supports (-ms-ime-align: auto) {
  .person-icon-value {
    transform: translate(17px, 24px) !important;
  }
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
  .person-icon-value {
    display: none !important;
  }

  .person-icon-value-ie {
    display: block !important;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.space-tooltip {
  margin-bottom: 7px;
}

input[disabled] {
  cursor: not-allowed;
}

.rmdBlueLine {
    padding-top: 4px !important;
}

.showAnimation {
   height: 45px;
}

.showAnimationProfile {
    height: 145px;
    margin-left:20px;
}


@media(max-width: 960px) {
    .showAnimationProfile {
        height: 230px;
    }
}

@media(max-width: 600px) {
    .showAnimationProfile {
        height: 365px;
    }
}

@media(max-width: 440px) {
    .showAnimationProfile {
        height: 415px;
    }
}


.showAnimationCase {
    height: 450px;
    margin-left: 0;
}

@media(max-width: 600px) {
    .showAnimationCase {
        height: 950px;
    }
}

.showAnimationCaseIndex {
    height: 400px;
    margin-left: 20px;
}

@media(max-width: 600px) {
    .showAnimationCaseIndex {
        height: 750px;
    }
}

.hideAnimationCase {
    transition-duration: 1s;
    transition-property: height, margin-top;
    margin-top: -440px;
    height: 0px;
    transition-timing-function: ease-in-out;
}

.hideAnimationProfile {
    transition-duration: 0.5s;
    transition-property: height, margin-top;
    margin-top: -40px;
    height: 0px;
    transition-timing-function: ease-in-out;
}

.hideAnimationIncome {
    transition-duration: 0.5s;
    transition-property: height, margin-top;
    margin-top: -190px;
    height: 0px;
    transition-timing-function: ease-in-out;
}

@media(min-width: 620px){
  .strategies-percentage-status.error{
    width: 120% !important;
  }
}

.logo {
  background-color: transparent !important;
  border-radius: 0 !important;
}

@media(min-width: 961px) {
  .logo {
    padding-left: 0px !important;
  } 
}

@media(max-width: 960px){
  .logo {
    padding-left: 12px !important;
  }
}

.years-deferral-error {
    display: block;
    white-space: nowrap;
}

@media(max-width: 321px) {
    .years-deferral-error {
        white-space: normal;
    }
}

.service-mark {
  font-size: 8px;
}

.trade-mark {
  font-size: 8px;
}